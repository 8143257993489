.mib-maintenance-wrapper {
    background: #f8f5f1;
    color: #3e3e3e;
    text-align: center;
    padding: 2rem;
    border-radius: 16px;
    border: 1px solid #d6ccc2;
    max-width: 500px;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
.mib-maintenance-icon {
    font-size: 60px;
    color: #2a8c45;
    display: inline-block;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
}
.mib-maintenance-icon:hover {
    transform: translateY(-5px);
}
.mib-maintenance-heading {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    color: #2a8c45;
}
.mib-maintenance-text {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
}
.mib-maintenance-link {
    color: #2a8c45;
    text-decoration: none;
    font-weight: bold;
}
.mib-maintenance-link:hover {
    text-decoration: underline;
}
@media (max-width: 600px) {
    .mib-maintenance-wrapper {
        padding: 1.5rem;
        margin: 1rem;
    }
    .mib-maintenance-icon {
        font-size: 48px;
    }
    .mib-maintenance-heading {
        font-size: 1.5rem;
    }
}
